<script setup lang="ts">
import { useCompanyStore } from '@/stores/companies'
import type { actions, domains } from '@/models/rbac'
const { $userCan } = useNuxtApp()
const companiesStore = useCompanyStore()
const route = useRouter()

const exitClientView = () => {
  companiesStore.clientViewCompany = null
  companiesStore.setCurrentCompany(null)
  const routeNav = route.currentRoute.value
  const action = routeNav.meta.allowedAction as actions
  const domain = routeNav.meta.allowedDomain as domains
  // handle client only tabs
  if (
    routeNav.fullPath.includes('campaign') ||
    routeNav.fullPath.includes('list')
  ) {
    navigateTo('/company')
    return
  }

  if (routeNav.params.companyId) {
    if (
      $userCan(action, domain, parseInt(routeNav.params.companyId as string))
    ) {
      navigateTo(routeNav.fullPath)
    } else {
      navigateTo({ path: '/company' })
    }
  } else if ($userCan(action, domain)) {
    navigateTo(routeNav.fullPath)
  } else {
    navigateTo({ path: '/company' })
  }
}
</script>

<template>
  <div class="w-full bg-mw-beige px-5 py-2">
    <div class="container">
      <div class="flex justify-between items-center">
        <div>
          <h4 class="m-0">
            Viewing Company:
            <span>{{ companiesStore?.clientViewCompany?.CompanyName }}</span>
          </h4>
        </div>
        <div>
          <button
            class="btn btn-sm btn-primary"
            @click.prevent="exitClientView"
          >
            Exit Client View
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
